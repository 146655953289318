import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import appID102Logo from './appID102.png';
// Import other logos as needed, e.g.:
// import appID103 from './logos/103.png';

// Create an object to map appIDs to their respective logos
const logoMap = {
  102: appID102Logo,
  // Add other appIDs and their corresponding logos here
  // 103: appID103Logo,
  // 104: appID104logo,
};

const appIDToLogo = {
  102: appID102Logo,
  // Add more mappings as needed
};

const AppContainer = styled(motion.div)`
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  background: rgba(255, 255, 255, 0.8);
  min-height: 100vh;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    padding: 40px 15px; // Further reduced padding for mobile
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const LogoContainer = styled(motion.div)`
  width: 100%;
  max-width: 200px; // Max width instead of fixed width
  height: auto; // Auto height to maintain aspect ratio
  margin-bottom: 20px; // Add some bottom margin

  @media (max-width: 768px) {
    max-width: 150px; // Smaller logo on mobile
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const RollupName = styled.h1`
  font-size: 2em;
  color: #1d1d1f;
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const RollupAddress = styled.a`
  font-size: 1em;
  color: #86868b;
  margin-bottom: 30px;
  text-align: center;
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // Allow wrapping on smaller screens
  gap: 20px; // Reduced gap
  margin-bottom: 60px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    gap: 15px; // Further reduced gap on mobile
  }
`;

const StatCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  width: calc(33.33% - 20px); // Calculate width based on container
  min-width: 200px; // Minimum width to prevent too narrow cards

  @media (max-width: 768px) {
    width: calc(50% - 15px); // Two cards per row on smaller screens
  }

  @media (max-width: 480px) {
    width: 100%; // Full width on very small screens
  }
`;

const StatLabel = styled.h2`
  font-size: 0.9em;
  color: #86868b;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const StatValue = styled.p`
  font-size: 1.5em;
  font-weight: 700;
  color: #1d1d1f;
  margin: 10px 0;
`;

const TableContainer = styled(motion.div)`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow-x: auto; // Allow horizontal scrolling on small screens
  max-width: 1000px;
  margin: 0 auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 600px; // Minimum width to prevent squishing
`;

const Th = styled.th`
  background-color: rgba(0, 0, 0, 0.03);
  color: #86868b;
  padding: 16px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Td = styled.td`
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: #1d1d1f;
  font-size: 0.9em;
  text-align: center;
`;

const Tr = styled(motion.tr)`
  &:last-child td {
    border-bottom: none;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 0.9em;
  color: #1d1d1f;
`;

const PageButton = styled.button`
  background: ${props => props.active ? '#1d1d1f' : 'transparent'};
  color: ${props => props.active ? 'white' : '#1d1d1f'};
  border: 1px solid #1d1d1f;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  transition: all 0.3s ease;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-size: 0.9em;
  border-radius: 5px;

  &:hover {
    background: ${props => props.disabled ? 'transparent' : '#1d1d1f'};
    color: ${props => props.disabled ? '#1d1d1f' : 'white'};
  }
`;

const BackLink = styled(Link)`
  display: inline-block;
  padding: 7px 10px;
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const BlockLink = styled.a`
  color: #007AFF;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SkeletonPulse = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
`;

const SkeletonLine = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${SkeletonPulse} 1.5s ease-in-out infinite;
`;

const TableSkeleton = () => (
  <TableContainer>
    <Table>
      <thead>
        <tr>
          <Th><SkeletonLine /></Th>
          <Th><SkeletonLine /></Th>
          <Th><SkeletonLine /></Th>
          <Th><SkeletonLine /></Th>
        </tr>
      </thead>
      <tbody>
        {[...Array(10)].map((_, index) => (
          <Tr key={index}>
            <Td><SkeletonLine /></Td>
            <Td><SkeletonLine /></Td>
            <Td><SkeletonLine /></Td>
            <Td><SkeletonLine /></Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  </TableContainer>
);

const LoadingOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const RollupLogo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const ApproxText = styled.span`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.6em;
  color: #86868b;
  font-style: italic;
`;

export const formatSize = (size) => {
  if (size === undefined || size === null) return 'N/A';
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;
  while (size >= 1024 && i < units.length - 1) {
    size /= 1024;
    i++;
  }
  return i === 0 ? `${Math.round(size)} ${units[i]}` : `${size.toFixed(2)} ${units[i]}`;
};

// Add GraphQL query constant at the top after imports
const STATS_QUERY = `
  query GetAppStats($appId: Int!) {
    dataSubmissions(
      filter: {
        appId: { equalTo: $appId }
      }
    ) {
      totalCount
      aggregates {
        distinctCount {
          id
        }
        sum {
          byteSize
          fees
        }
      }
    }
  }
`;

// Add loading overlay styled components
const PageLoadingOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const PageSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
`;

function RollupDetails() {
  const { appId, network } = useParams();  // Get both appId and network from URL
  const [rollupData, setRollupData] = useState(null);
  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [rollupStats, setRollupStats] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  // Function to get the correct GraphQL endpoint based on network
  const getGraphQLEndpoint = () => {
    return network === 'testnet' 
      ? 'https://turing-indexer.avail.so/'
      : 'https://indexer.avail.so/';
  };

  // Fetch GraphQL stats
  useEffect(() => {
    const fetchGraphQLStats = async () => {
      try {
        const endpoint = getGraphQLEndpoint();
        const response = await axios.post(endpoint, {
          query: STATS_QUERY,
          variables: {
            appId: parseInt(appId)
          }
        });

        const statsData = response.data.data.dataSubmissions;
        setRollupStats({
          total_da_submissions: statsData.totalCount,
          total_data_size: parseInt(statsData.aggregates.sum.byteSize),
          total_fees: statsData.aggregates.sum.fees
        });
      } catch (error) {
        console.error('Error fetching GraphQL stats:', error);
        setRollupStats({
          total_da_submissions: 0,
          total_data_size: 0,
          total_fees: 0
        });
      }
    };

    fetchGraphQLStats();
  }, [appId, network]);

  // Format fees with 2 decimal places
  const formatFees = (fees) => {
    if (fees === undefined || fees === null) return 'N/A';
    return `${fees.toFixed(2)} AVAIL`;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchEntries = async () => {
      setIsTableLoading(true);
      try {
        const apiBaseUrl = 'https://freeblockspace.xyz/api';
        const response = await axios.get(
          `${apiBaseUrl}/rollup/${appId}/entries?page=${currentPage}&network=${network}`
        );
        setEntries(response.data.entries);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        setIsTableLoading(false);
      }
    };

    fetchEntries();
  }, [appId, currentPage, network]);  // Add network to dependencies

  useEffect(() => {
    const fetchRollupData = async () => {
      try {
        const apiBaseUrl = 'https://freeblockspace.xyz/api';
        console.log(`Fetching rollup data from: ${apiBaseUrl}/rollup/${appId}?network=${network}`);
        const rollupResponse = await axios.get(`${apiBaseUrl}/rollup/${appId}?network=${network}`);
        console.log('Rollup response:', rollupResponse.data);
        setRollupData(rollupResponse.data);
        setRollupStats({
          total_da_submissions: rollupResponse.data.total_da_submissions || 0,
          total_data_size: rollupResponse.data.total_data_size || 0
        });
      } catch (error) {
        console.error('Error fetching rollup data:', error);
      }
    };

    fetchRollupData();
  }, [appId, network]);  // Add network to dependencies

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToFirstPage = () => setCurrentPage(1);
  const goToLastPage = () => setCurrentPage(totalPages);
  const goToPreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const goToNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

  // Increased delay to 800ms
  useEffect(() => {
    setIsPageLoading(true);
    const timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 800); // Increased from 500ms to 800ms

    return () => clearTimeout(timer);
  }, [appId, network]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {isPageLoading && (
        <PageLoadingOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }} // Smooth fade for overlay
        >
          <PageSpinner />
        </PageLoadingOverlay>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }} // Slightly longer fade-in for content
      >
        <AppContainer>
          <BackLink to="/">← Back</BackLink>
          <HeaderContainer>
            {logoMap[appId] && (
              <RollupLogo 
                src={logoMap[appId]} 
                alt={`${rollupData?.rollup} logo`}
              />
            )}
            <RollupName>{rollupData?.rollup}</RollupName>
          </HeaderContainer>
          <RollupAddress 
            href={`https://${network === 'mainnet' ? 'avail' : 'avail-turing'}.subscan.io/account/${rollupData?.address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rollupData?.address}
          </RollupAddress>
          <StatsContainer>
            <StatCard>
              <StatLabel>Total DA Submissions</StatLabel>
              <StatValue>
                {!isPageLoading && rollupStats ? 
                  rollupStats.total_da_submissions.toLocaleString() : 
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0 }}
                  >
                    &nbsp;
                  </motion.div>
                }
              </StatValue>
            </StatCard>
            <StatCard>
              <StatLabel>Total Blob Size</StatLabel>
              <StatValue>
                {!isPageLoading && rollupStats ? 
                  formatSize(rollupStats.total_data_size) : 
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0 }}
                  >
                    &nbsp;
                  </motion.div>
                }
              </StatValue>
            </StatCard>
            <StatCard>
              <StatLabel>Total Blob Cost</StatLabel>
              <StatValue>
                {!isPageLoading && rollupStats ? 
                  formatFees(rollupStats.total_fees) : 
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0 }}
                  >
                    &nbsp;
                  </motion.div>
                }
              </StatValue>
            </StatCard>
          </StatsContainer>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <Th>appID</Th>
                  <Th>Block</Th>
                  <Th>Data Hash</Th>
                  <Th>Data Size</Th>
                </tr>
              </thead>
              <AnimatePresence mode="wait">
                <motion.tbody
                  key={currentPage}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {entries.map((entry) => (
                    <Tr key={`${entry.app_id}-${entry.extrinsic}`}>
                      <Td>{entry.app_id}</Td>
                      <Td>
                        <BlockLink
                          href={`https://${network === 'mainnet' ? 'avail' : 'avail-turing'}.subscan.io/block/${entry.extrinsic.split('-')[0]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {entry.extrinsic.split('-')[0]}
                        </BlockLink>
                      </Td>
                      <Td>{entry.data_hash}</Td>
                      <Td>{formatSize(entry.size)}</Td>
                    </Tr>
                  ))}
                </motion.tbody>
              </AnimatePresence>
            </Table>
            {isTableLoading && (
              <LoadingOverlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Spinner />
              </LoadingOverlay>
            )}
          </TableContainer>
          <Pagination>
            <PageButton onClick={goToFirstPage} disabled={currentPage === 1}>First</PageButton>
            <PageButton onClick={goToPreviousPage} disabled={currentPage === 1}>&lt;</PageButton>
            <PageInfo>Page {currentPage} of {totalPages}</PageInfo>
            <PageButton onClick={goToNextPage} disabled={currentPage === totalPages}>&gt;</PageButton>
            <PageButton onClick={goToLastPage} disabled={currentPage === totalPages}>Last</PageButton>
          </Pagination>
        </AppContainer>
      </motion.div>
    </>
  );
}

export default RollupDetails;