import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import logoImage from './logo.png'; // Import the logo image
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RollupDetails from './RollupDetails';
import { Link } from 'react-router-dom';

// Main container - adjust max-width to change overall app width
const AppContainer = styled.div`
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px; // Reduced side padding
  background: rgba(255, 255, 255, 0.8);
  min-height: 100vh;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    padding: 40px 15px; // Further reduced padding for mobile
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px; // Adjust this value to move the logo down
`;

// Logo container - adjust width and height to change logo size
const LogoContainer = styled.div`
  width: 100%;
  max-width: 400px; // Max width instead of fixed width
  height: auto; // Auto height to maintain aspect ratio
  margin-bottom: 30px; // Add some bottom margin

  @media (max-width: 768px) {
    max-width: 300px; // Smaller logo on mobile
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

// Stats container - adjust gap and max-width to change layout of stat boxes
const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap; // Allow wrapping on smaller screens
  gap: 20px; // Reduced gap
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    gap: 15px; // Further reduced gap on mobile
  }
`;

// Stat card - adjust width, padding, and font-size to change size and text fit
const StatCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.7);
  padding: 20px; // Adjust this value to change padding inside stat boxes
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  width: calc(33.33% - 20px); // Calculate width based on container
  min-width: 200px; // Minimum width to prevent too narrow cards
  position: relative;

  @media (max-width: 768px) {
    width: calc(50% - 15px); // Two cards per row on smaller screens
  }

  @media (max-width: 480px) {
    width: 100%; // Full width on very small screens
  }
`;

// Stat value - adjust font-size to change size of stat value text
const StatValue = styled.p`
  font-size: 1.5em; // Adjust this value to change size of stat value text
  font-weight: 700;
  color: #1d1d1f;
  margin: 10px 0;
`;

// Stat label - adjust font-size to change size of stat label text
const StatLabel = styled.h2`
  font-size: 0.9em; // Adjust this value to change size of stat label text
  color: #86868b;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

// Table container - adjust max-width to change table width
const TableContainer = styled(motion.div)`
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow-x: auto; // Allow horizontal scrolling on small screens
  max-width: 1000px; // Adjust this value to change table width
  margin: 0 auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 600px; // Minimum width to prevent squishing
`;

// Table header - adjust padding and font-size to change header cell size and text
const Th = styled.th`
  background-color: rgba(0, 0, 0, 0.03);
  color: #86868b;
  padding: 16px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

// Table cell - adjust padding and font-size to change cell size and text
const Td = styled.td`
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: #1d1d1f;
  font-size: 0.9em;
  text-align: center;
`;

const RollupName = styled.span`
  font-weight: 600;
  font-size: 1.1em;
  color: #1d1d1f;
`;

const Tr = styled(motion.tr)`
  &:last-child td {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const ApproxText = styled.span`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.6em;
  color: #86868b;
  font-style: italic;
`;

// Add these styled components after your existing styled components
const NetworkToggle = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
`;

const NetworkButton = styled.button`
  padding: 10px 20px;
  border-radius: 15px;
  border: 1px solid #1d1d1f;
  background: ${props => props.active ? '#1d1d1f' : 'transparent'};
  color: ${props => props.active ? 'white' : '#1d1d1f'};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9em;
  
  &:hover {
    background: ${props => props.active ? '#1d1d1f' : 'rgba(29, 29, 31, 0.1)'};
  }
`;

// Add GraphQL query constant at the top after imports
const STATS_QUERY = `
  {
    dataSubmissions {
      totalCount
      aggregates {
        distinctCount {
          id
        }
        sum {
          byteSize
          fees
        }
      }
    }
  }
`;

// Add these styled components after your existing styled components
const SkeletonLoader = styled.div`
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.1) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
  height: 24px;
  width: 80%;
  margin: 10px auto;

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

// Add these styled components for the loading spinner
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
`;

function App() {
  const [network, setNetwork] = useState('testnet');
  const [rollups, setRollups] = useState([]);
  const [stats, setStats] = useState({ total_data_size: 0, total_fees: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [cache, setCache] = useState({
    testnet: { stats: null, rollups: null, timestamp: null },
    mainnet: { stats: null, rollups: null, timestamp: null }
  });
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Cache duration in milliseconds (5 minutes)
  const CACHE_DURATION = 5 * 60 * 1000;

  const isCacheValid = (timestamp) => {
    return timestamp && (Date.now() - timestamp) < CACHE_DURATION;
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsInitialLoading(true);
      try {
        const graphqlEndpoint = network === 'testnet' 
          ? 'https://turing-indexer.avail.so/'
          : 'https://indexer.avail.so/';

        const [rollupsResponse, graphqlResponse] = await Promise.all([
          axios.get(`https://freeblockspace.xyz/api/rollups/${network}`),
          axios.post(graphqlEndpoint, {
            query: `{
              dataSubmissions {
                totalCount
                aggregates {
                  distinctCount {
                    id
                  }
                  sum {
                    byteSize
                    fees
                  }
                }
              }
            }`
          })
        ]);

        const statsData = graphqlResponse.data.data.dataSubmissions;
        const newStats = {
          total_data_size: parseInt(statsData.aggregates.sum.byteSize),
          total_fees: statsData.aggregates.sum.fees
        };

        setStats(newStats);
        setRollups(rollupsResponse.data);
        
        // Update cache
        setCache(prevCache => ({
          ...prevCache,
          [network]: {
            stats: newStats,
            rollups: rollupsResponse.data,
            timestamp: Date.now()
          }
        }));
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsInitialLoading(false);
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []); // Empty dependency array for initial load only

  useEffect(() => {
    const fetchData = async () => {
      // Check if we have valid cached data
      if (cache[network].stats && cache[network].rollups && isCacheValid(cache[network].timestamp)) {
        setStats(cache[network].stats);
        setRollups(cache[network].rollups);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        // Get the correct GraphQL endpoint
        const graphqlEndpoint = network === 'testnet' 
          ? 'https://turing-indexer.avail.so/'
          : 'https://indexer.avail.so/';

        // Fetch data in parallel
        const [rollupsResponse, graphqlResponse] = await Promise.all([
          axios.get(`https://freeblockspace.xyz/api/rollups/${network}`),
          axios.post(graphqlEndpoint, {
            query: `{
              dataSubmissions {
                totalCount
                aggregates {
                  distinctCount {
                    id
                  }
                  sum {
                    byteSize
                    fees
                  }
                }
              }
            }`
          })
        ]);

        const statsData = graphqlResponse.data.data.dataSubmissions;
        const newStats = {
          total_data_size: parseInt(statsData.aggregates.sum.byteSize),
          total_fees: statsData.aggregates.sum.fees
        };

        // Update cache
        setCache(prevCache => ({
          ...prevCache,
          [network]: {
            stats: newStats,
            rollups: rollupsResponse.data,
            timestamp: Date.now()
          }
        }));

        // Update state
        setStats(newStats);
        setRollups(rollupsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [network]);

  // Network switch handler with smooth transition
  const handleNetworkSwitch = (newNetwork) => {
    if (newNetwork === network) return;
    
    // If we have cached data, show it immediately
    if (cache[newNetwork].stats && cache[newNetwork].rollups && isCacheValid(cache[newNetwork].timestamp)) {
      setStats(cache[newNetwork].stats);
      setRollups(cache[newNetwork].rollups);
    } else {
      // Show loading state only if we don't have cached data
      setIsLoading(true);
    }
    
    setNetwork(newNetwork);
  };

  const formatSize = (bytes) => {
    if (bytes === undefined || bytes === null) return 'N/A';
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = bytes;
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  };

  const formatFees = (fees) => {
    if (fees === undefined || fees === null) return 'N/A';
    return `${fees.toFixed(2)} AVAIL`;
  };

  const StatCardContent = ({ label, value, isLoading }) => (
    <StatCard
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <StatLabel>{label}</StatLabel>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <StatValue>{value}</StatValue>
      )}
    </StatCard>
  );

  return (
    <Router>
      <motion.div>
        {isInitialLoading && (
          <LoadingOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Spinner />
          </LoadingOverlay>
        )}
        <Routes>
          <Route path="/" element={
            <AppContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: isInitialLoading ? 0 : 1 }}
              transition={{ duration: 0.5 }}
            >
              <HeaderContainer>
                <LogoContainer>
                  <LogoImage src={logoImage} alt="Rollups on Avail Logo" />
                </LogoContainer>
              </HeaderContainer>
              
              <NetworkToggle>
                <NetworkButton 
                  active={network === 'testnet'}
                  onClick={() => handleNetworkSwitch('testnet')}
                  disabled={isLoading}
                >
                  Testnet
                </NetworkButton>
                <NetworkButton 
                  active={network === 'mainnet'}
                  onClick={() => handleNetworkSwitch('mainnet')}
                  disabled={isLoading}
                >
                  Mainnet
                </NetworkButton>
              </NetworkToggle>

              <StatsContainer>
                <StatCardContent 
                  label="Total Blobs Size"
                  value={formatSize(stats.total_data_size)}
                  isLoading={isLoading}
                />
                <StatCardContent 
                  label="Total Blob Cost"
                  value={formatFees(stats.total_fees)}
                  isLoading={isLoading}
                />
              </StatsContainer>

              <TableContainer
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
              >
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table>
                    <thead>
                      <tr>
                        <Th>Rollup</Th>
                        <Th>appID</Th>
                        <Th>Addresses</Th>
                        <Th>Blobs Submitted</Th>
                      </tr>
                    </thead>
                    <tbody>
                      {rollups.map((rollup, index) => (
                        <Tr
                          key={rollup.app_id}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.3, delay: 0.1 * index }}
                        >
                          <Td>
                            <StyledLink to={`/${network}/rollup/${rollup.app_id}`}>
                              <RollupName>{rollup.rollup}</RollupName>
                            </StyledLink>
                          </Td>
                          <Td>{rollup.app_id}</Td>
                          <Td>{rollup.address}</Td>
                          <Td>{formatSize(rollup.total_size)}</Td>
                        </Tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </TableContainer>
            </AppContainer>
          } />
          <Route 
            path="/:network/rollup/:appId" 
            element={<RollupDetails cache={cache} setCache={setCache} />} 
          />
        </Routes>
      </motion.div>
    </Router>
  );
}

// Add a TableSkeleton component for smooth loading
const TableSkeleton = () => (
  <div style={{ opacity: 0.7 }}>
    {/* Add skeleton loading animation here */}
  </div>
);

// Optional: Add a fade-in animation for the content
const FadeIn = styled(motion.div)`
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default App;